import { FC, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import Grid from '@mui/material/Grid2';
import { Field, useFormikContext } from 'formik';
import { Input } from '@components/input';
import { SwitchComponent } from '@components/ui-kit/switch';
import { CreateProjectForm } from '@pages/create-project/create-project';
import { CalculationRequestData, CalculationResponse, mapDTOToValues, } from '@api/projects/mutations';
import { UseMutateFunction } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';

interface SecondStepProps {
  calculateBuildingPerformance: UseMutateFunction<any, Error, CalculationRequestData, unknown>;
}

export const SecondStep: FC<SecondStepProps> = ({calculateBuildingPerformance}) => {
  const { t } = useTranslation();
  const {values, setFieldValue, errors, touched, setValues} = useFormikContext<CreateProjectForm>();
  const [debouncedValues, setDebouncedValues] = useState({
    heating_performance_total: values.heating_performance_total,
    heating_consumption_total: values.heating_consumption_total,
  });

  useEffect(() => {
    if (values.calculateSeparately) {
      toast.warning('This step is under development and is not ready yet');
    }
  }, [values.calculateSeparately]);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValues({
        heating_performance_total: values.heating_performance_total,
        heating_consumption_total: values.heating_consumption_total,
      });
    }, 500);

    return () => {
      clearTimeout(handler);
    };
  }, [values.heating_performance_total, values.heating_consumption_total]);

  useEffect(() => {
    if (
      debouncedValues.heating_performance_total !== '' &&
      debouncedValues.heating_consumption_total !== ''
    ) {
      calculateBuildingPerformance({
          heated_area: values.heated_area as number,
          heating_performance_total: debouncedValues.heating_performance_total,
          heating_consumption_total: debouncedValues.heating_consumption_total,
        },
        {
          onSuccess: (response: CalculationResponse) => {
            const mappedValues = mapDTOToValues(response, values);
            setValues(mappedValues);
          },
          onError: () => {
            toast.error('Calculation failed. Please try again.');
          },
        });
    }
  }, [debouncedValues, calculateBuildingPerformance, values.heated_area]);

  return (
    <div className="flex flex-col">
      <Grid container spacing={3}>
        <Grid size={12}>
          <div className="flex justify-center">
            <Field
              name="calculateSeparately"
              as={SwitchComponent}
              checked={values.calculateSeparately}
              setChecked={setFieldValue}
              leftLabel={t('project.calculationByTotalValues')}
              rightLabel={t('project.calculationBySeparateValues')}
            />
          </div>
        </Grid>

        <Grid size={6}>
          <Field
            as={Input}
            label={t('project.heatingPerformanceTotal')}
            name="heating_performance_total"
            errors={errors}
            touched={touched}
            disabled={values.calculateSeparately}
            type="number"
            adornment="kW"
            fullWidth
            isRequired
          />
        </Grid>

        <Grid size={6}>
          <Field
            as={Input}
            label={t('project.heatingConsumptionTotal')}
            name="heating_consumption_total"
            errors={errors}
            touched={touched}
            disabled={values.calculateSeparately}
            type="number"
            adornment="MWh"
            fullWidth
            isRequired
          />
        </Grid>

        <Grid size={6}>
          <Field
            as={Input}
            label={t('project.heatingPower')}
            name="heating_power"
            errors={errors}
            touched={touched}
            disabled={!values.calculateSeparately}
            type="number"
            adornment="kW"
            fullWidth
            isRequired
          />
        </Grid>

        <Grid size={6}>
          <Field
            as={Input}
            label={t('project.heatingConsumption')}
            name="heating_consumption"
            errors={errors}
            touched={touched}
            disabled={!values.calculateSeparately}
            type="number"
            adornment="MWh"
            fullWidth
            isRequired
          />
        </Grid>

        <Grid size={6}>
          <Field
            as={Input}
            label={t('project.warmWaterPower')}
            name="warm_water_power"
            errors={errors}
            touched={touched}
            disabled={!values.calculateSeparately}
            type="number"
            adornment="kW"
            fullWidth
            isRequired
          />
        </Grid>

        <Grid size={6}>
          <Field
            as={Input}
            label={t('project.warmWaterConsumption')}
            name="warm_water_consumption"
            errors={errors}
            touched={touched}
            disabled={!values.calculateSeparately}
            type="number"
            adornment="MWh"
            fullWidth
            isRequired
          />
        </Grid>

        <Grid size={6}>
          <Field
            as={Input}
            label={t('project.temperatureConditioningPower')}
            name="temperature_conditioning_power"
            errors={errors}
            touched={touched}
            disabled={!values.calculateSeparately}
            type="number"
            adornment="kW"
            fullWidth
            isRequired
          />
        </Grid>

        <Grid size={6}>
          <Field
            as={Input}
            label={t('project.temperatureConditioningConsumption')}
            name="temperature_conditioning_consumption"
            errors={errors}
            touched={touched}
            disabled={!values.calculateSeparately}
            type="number"
            adornment="MWh"
            fullWidth
            isRequired
          />
        </Grid>
      </Grid>
    </div>
  );
};
