import React, { FC } from 'react';
import { Button as MUIButton, ButtonProps as MUIButtonProps } from '@mui/material';

interface CustomButtonProps extends MUIButtonProps {
  customClass?: string;
  variantType?: 'contained' | 'text' | 'outlined';
}

export const Button: FC<CustomButtonProps> = ({ customClass, variantType = 'contained', children, ...props }) => {
  return (
    <MUIButton
      variant={variantType}
      className={customClass}
      {...props}
    >
      {children}
    </MUIButton>
  );
};
