import { ReactNode, useState } from 'react';

interface UseStepperProps {
  steps: ReactNode[];
}

const useStepper = ({ steps }: UseStepperProps) => {
  const [activeStep, setActiveStep] = useState<number>(0);

  const handleNext = () => {
    if (activeStep < steps.length - 1) {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }
  };

  const handleBack = () => {
    if (activeStep > 0) {
      setActiveStep((prevActiveStep) => prevActiveStep - 1);
    }
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  return {
    activeStep,
    steps,
    isLastStep: activeStep === steps.length - 1,
    handleNext,
    handleBack,
    handleReset,
  };
};

export default useStepper;
