import { FC, Dispatch , SetStateAction } from 'react';
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import { Button } from '@components/ui-kit/button';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

interface DialogProps {
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  onConfirm: () => void;
  title: string;
  text: string;
}

export const DialogWrapper: FC<DialogProps> = ({ isOpen, setIsOpen, title, text, onConfirm }) => {

  const handleConfirm = () => {
    handleClose();
    onConfirm();
  };

  const handleClose = () => {
    setIsOpen(false);
  };

  return (
    <Dialog
      open={isOpen}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle sx={{ minWidth: '444px', color: '#18181B' }} id="alert-dialog-title">
        {title}
      </DialogTitle>
      <DialogContent>
        <DialogContentText sx={{ color: '#494962' }} id="alert-dialog-description">
          <InfoOutlinedIcon sx={{ color: '#A1A1AA', mr: '8px' }} /> {text}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button variant="text" onClick={handleClose}>Cancel</Button>
        <Button variant="text" onClick={handleConfirm}>
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  );
};
