import React, { FC } from 'react';
import { Box } from '@mui/material';

export const TabPanel: FC<{ children: React.ReactNode; value: number; index: number }> = ({children, value, index}) => {
  return (
    <div role="tabpanel" hidden={value !== index} id={`tab-panel-${index}`} aria-labelledby={`tab-${index}`}>
      {value === index && <Box pt={5}>{children}</Box>}
    </div>
  );
};
