import { endpoint } from '@api/api';
import { useMutation } from '@tanstack/react-query';
import { CreateProjectForm } from '@pages/create-project/create-project';
import { DEFAULT_OPTIMIZATION_FOR_PV } from '@api/projects/constants';

export interface CalculationRequestData {
  heated_area: number;
  heating_performance_total: number;
  heating_consumption_total: number;
}

export interface CalculationResponse {
  heating_power: number;
  heating_consumption: number;
  warm_water_power: number;
  warm_water_consumption: number;
  temperature_conditioning_power: number;
  temperature_conditioning_consumption: number;
  heating_performance_total: number;
  heating_consumption_total: number;
  spec_heating_power: number;
  spec_heating_consumption: number;
  spec_warm_water_power: number;
  spec_warm_water_consumption: number;
  spec_temperature_conditioning_power: number;
  spec_temperature_conditioning_consumption: number;
  spec_heating_performance_total: number;
  spec_heating_consumption_total: number;
}

export interface ProjectResponse {
  id: string;
  postal_code: string;
  project_name: string;
  customer_name: string;
  created_at: string;
  inputs: {
    heated_area: number;
    heating_power: number;
    heating_consumption: number;
    warm_water_power: number;
    warm_water_consumption: number;
    temperature_conditioning_power: number;
    temperature_conditioning_consumption: number;
    heating_performance_total: number;
    heating_consumption_total: number;
    spec_heating_power: number;
    spec_heating_consumption: number;
    spec_warm_water_power: number;
    spec_warm_water_consumption_central: number;
    spec_temperature_conditioning_power: number;
    spec_temperature_conditioning_consumption: number;
    spec_heating_performance_total: number;
    spec_heating_consumption_total: number;
    optimization_for_pv: number;
    with_thermal_renovation: boolean;
    exterior_area: number;
    roof_area: number;
    modultyp: string;
    orientation: string;
    inclination_of_roof: string;
    energy_price_consumer_heat_and_ww_net: number;
    energy_price_consumer_temp_net: number;
    subsidy_public_grant_on_tic: number;
    electricity_price_index_vs_cpi: number;
  };
  outputs: {
    Information: {
      'Project Name': string;
      'Customer': string;
      'Heated Area': number;
      'Thermal Renovation': boolean;
    };
    Consumption: {
      Heating: number;
      'Warm Water': number;
      Tempering: number;
      Total: number;
    };
    Offer: {
      'Case 1': OfferDetails;
      'Case 2': OfferDetails;
      'Case 3': OfferDetails;
    };
    'Offer E-a-a-S': {
      'Case 1': OfferEaasDetails;
      'Case 2': OfferEaasDetails;
      'Case 3': OfferEaasDetails;
    };
    'Offer GC': {
      'Case 1': OfferGCDetails;
      'Case 2': OfferGCDetails;
      'Case 3': OfferGCDetails;
    };
  };
}

export interface OfferDetails {
  'Electrical power HP': number | string;
  'Electrical power AHEx': number | string;
  'Electrical power AHP': number | string;
  'PV used roof area': number;
  'PV performance': number;
  'Probes length total': number | string;
  'Probes field area': number | string;
  'CHECK Exterior area vs. Probes field area': string;
  'Total Power requirement': number;
  'PV yield total': number;
  'Total electricity demand from grid': number;
}

export interface OfferEaasDetails {
  TIC: number;
  'TIC specific': number;
  'TIC specific (only HVAC)': number;
  'Subsidy/public grant (assumed)': number;
  'Credit/Loan': number;
  'TIC contribution by customer (BKZ)': number;
  'TIC contribution (BKZ) per m2': number;
  'TIC contribution (BKZ) per WE': number;
  'Average energy price total (HEAT+WW+TEMP)': number;
  'Average energy price specific p.a. (HEAT+WW+TEMP)': number;
  'Average energy price specific p.m. (HEAT+WW+TEMP)': number;
  'Total cost on 20y horizon': number;
  'End of Offer to Customer': number[];
}

export interface OfferGCDetails {
  TIC: number;
  'Subsidy/public grant': number;
  'GC margin': number;
  'Total Cost to customer': number;
  'TCC p.m2': number;
  'TCC p.WE': number;
  'Average OPEX total (HEAT+WW+TEMP)': number;
  'Average OPEX specific p.a. (HEAT+WW+TEMP)': number;
  'Average OPEX specific p.m. (HEAT+WW+TEMP)': number;
  'Total cost on 20y horizon': number;
  'End of Offer to Customer': number[];
}

// Mappers
export const mapDTOToValues = (data: CalculationResponse, currentValues: CreateProjectForm) => {
  return {
    ...currentValues,
    heating_power: data.heating_power,
    heating_consumption: data.heating_consumption,
    warm_water_power: data.warm_water_power,
    warm_water_consumption: data.warm_water_consumption,
    temperature_conditioning_power: data.temperature_conditioning_power,
    temperature_conditioning_consumption: data.temperature_conditioning_consumption,
    heating_performance_total: data.heating_performance_total,
    heating_consumption_total: data.heating_consumption_total,
  };
};

export const mapValuesToOfferDTO = (values: CreateProjectForm) => {
  return {
    postal_code: values.postal_code,
    project_name: values.project_name,
    customer_name: values.customer_name,
    inputs: {
      energy_price_consumer_heat_and_ww_net: values.energy_price_consumer_heat_and_ww_net,
      energy_price_consumer_temp_net: values.energy_price_consumer_temp_net,
      subsidy_public_grant_on_tic: values.subsidy_public_grant_on_tic,
      electricity_price_index_vs_cpi: values.electricity_price_index_vs_cpi,
      optimization_for_pv: DEFAULT_OPTIMIZATION_FOR_PV,
      heated_area: values.heated_area,
      exterior_area: values.exterior_area,
      roof_area: values.roof_area,
      heating_performance_total: values.heating_performance_total,
      heating_consumption_total: values.heating_consumption_total,
      orientation: values.orientation,
      inclination_of_roof: values.inclination_of_roof,
      with_thermal_renovation: values.with_thermal_renovation,
    }
  };
};


// Mutations
const sendCalculationRequest = async (data: CalculationRequestData) => {
  const response = await endpoint.post(
    'performance_and_consumption_of_building/',
    data
  );

  return response.data;
};

const offerCalculationMutation = async (data: CreateProjectForm) => {
  const response = await endpoint.post('calculate_offers/', mapValuesToOfferDTO(data));
  return response.data;
};

const createProject = async (project: ProjectResponse) => {
  const response = await endpoint.post('project/', project);
  return response.data;
};


// Hooks
export const useCalculationMutation = () => {
  return useMutation({
    mutationFn: (data: CalculationRequestData) => sendCalculationRequest(data),
  });
};

export const useOfferCalculationMutation = () => {
  return useMutation({
    mutationFn: (data: CreateProjectForm) => offerCalculationMutation(data)
  });
};

export const useCreateProjectMutation = () => {
  return useMutation({
    mutationFn: (data: ProjectResponse) => createProject(data)
  });
};
