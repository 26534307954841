import React, { FC, useCallback } from 'react';
import { Box, Divider, Table, TableCell, TableHead, TableRow } from '@mui/material';
import Grid from '@mui/material/Grid2';
import { useFormikContext } from 'formik';
import { UseMutateFunction } from '@tanstack/react-query';
import { ProjectResponse } from '@api/projects/mutations';
import { CreateProjectForm } from '@pages/create-project/create-project';
import { TabsComponent } from '@components/ui-kit/tabs';
import { TabPanel } from '@components/ui-kit/tab-panel';
import { OfferField } from '@components/ui-kit/offer-field';
import { FormCheckbox } from '@components/ui-kit/checkbox';
import { OfferInput } from '@components/ui-kit/offer-input';
import { ElectricityDemandGraph } from '@components/create-project/graphs/electricity-demand-graph';
import { OfferTable } from '@components/create-project/table/offer-table';
import { OfferEaasTable } from '@components/create-project/table/offer-eaas-table';
import { OfferGcTable } from '@components/create-project/table/offer-gc-table';
import { OfferEaasGraph } from '@components/create-project/graphs/offer-eaas-graph';
import { useTabs } from '@hooks/use-tabs';
import debounce from '@utils/utils';
import { OfferGcGraph } from '@components/create-project/graphs/offer-gc-graph';
import { useTranslation } from 'react-i18next';

interface FinalStepProps {
  offer: ProjectResponse;
  calculateOffer: UseMutateFunction<any, Error, CreateProjectForm, unknown>;
}

export const FinalStep: FC<FinalStepProps> = ({offer, calculateOffer}) => {
  const { t } = useTranslation();
  const {value, handleChange} = useTabs(0);
  const {values, setValues} = useFormikContext<CreateProjectForm>();
  const tabLabels = ['Offer', 'Offer E-a-a-S', 'Offer GC'];

  const debouncedCalculateOffer = useCallback(
    debounce((updatedValues: CreateProjectForm) => {
      calculateOffer(updatedValues);
    }, 600),
    [calculateOffer]
  );

  const handleOfferInputChange = useCallback((name: string, value: number) => {
    const updatedValues = {...values, [name]: value};

    // Update Formik values
    setValues(updatedValues);

    // Call the API to calculate offer with the updated input after debounce
    debouncedCalculateOffer(updatedValues);
  }, [values, setValues, debouncedCalculateOffer]);

  const handleThermalRenovationChange = useCallback(() => {
    const updatedValue = !values['with_thermal_renovation'];
    const updatedValues = {...values, with_thermal_renovation: updatedValue};

    setValues(updatedValues);
    debouncedCalculateOffer(updatedValues);
  }, [values, setValues, debouncedCalculateOffer]);

  const renderInfo = () => (
    <Grid container columnSpacing={2}>
      <Grid size={4}>
        <p className="text-black-800 font-medium text-sm mb-2">{t('project.table.informationTitle')}</p>
        <OfferField name={t('project.projectName')} value={offer?.outputs.Information['Project Name']} />
        <OfferField name={t('project.customerName')} value={offer?.outputs.Information['Customer']} />
        <OfferField name={t('project.heatedArea')} value={offer?.outputs.Information['Heated Area']} units="m2" />
        <OfferField
          name={t('project.thermalRenovation')}
          value={
            <FormCheckbox
              className="pr-0"
              checked={values.with_thermal_renovation}
              onChange={handleThermalRenovationChange}
            />
          }
          noBorder
        />
      </Grid>
      <Grid size={4} sx={{ pl: 2, borderLeft: '1px solid #18181B1F' }}>
        <p className="text-black-800 font-medium text-sm mb-2">{t('project.table.consumptionTitle')}</p>
        <OfferField name={t('project.table.heating')} value={offer?.outputs.Consumption.Heating} units="MWh" />
        <OfferField name={t('project.table.warmWater')} value={offer?.outputs.Consumption['Warm Water']} units="MWh" />
        <OfferField name={t('project.table.tempering')} value={offer?.outputs.Consumption.Tempering} units="MWh" />
        <OfferField name={t('project.table.total')} value={offer?.outputs.Consumption.Total} units="MWh" noBorder />
      </Grid>
      <Grid size={4} sx={{ pl: 2, borderLeft: '1px solid #18181B1F' }}>
        <p className="text-black-800 font-medium text-sm mb-2">{t('project.table.pricingAssumptionsTitle')}</p>
        <OfferInput
          name="energy_price_consumer_heat_and_ww_net"
          label={t('project.table.energyPriceConsumerHeatWWNet')}
          units="c.EUR/m2*a"
          onChange={handleOfferInputChange}
        />
        <OfferInput
          name="energy_price_consumer_temp_net"
          label={t('project.table.energyPriceConsumerTempNet')}
          units="c.EUR/m2*a"
          onChange={handleOfferInputChange}
        />
        <OfferInput
          name="subsidy_public_grant_on_tic"
          label={t('project.table.subsidyPublicGrantOnTic')}
          units="%"
          onChange={handleOfferInputChange}
        />
        <OfferInput
          name="electricity_price_index_vs_cpi"
          label={t('project.table.electricityPriceIndexVsCpi')}
          onChange={handleOfferInputChange}
        />
      </Grid>
    </Grid>
  );

  const renderTableHead = () => (
    <TableHead>
      <TableRow>
        <TableCell sx={{ borderBottom: 'none' }}>{t('project.table.title')}</TableCell>
        <TableCell sx={{ borderBottom: 'none' }}>
          <p className="font-medium text-black-800 text-center">{t('project.table.case1.heading')}</p>
          <p className="text-metallic-800 text-center">{t('project.table.case1.subHeading')}</p>
        </TableCell>
        <TableCell sx={{ borderBottom: 'none' }}>
          <p className="font-medium text-black-800 text-center">{t('project.table.case2.heading')}</p>
          <p className="text-metallic-800 text-center">{t('project.table.case2.subHeading')}</p>
        </TableCell>
        <TableCell sx={{ borderBottom: 'none' }}>
          <p className="font-medium text-black-800 text-center">{t('project.table.case3.heading')}</p>
          <p className="text-metallic-800 text-center">{t('project.table.case3.subHeading')}</p>
        </TableCell>
      </TableRow>
    </TableHead>
  );

  return (
    <Box display="flex" flexDirection="column" width="100%">
      <TabsComponent value={value} handleChange={handleChange} tabLabels={tabLabels} />
      <TabPanel value={value} index={0}>
        {renderInfo()}

        <Divider variant="fullWidth" sx={{ marginTop: '30px', marginBottom: '40px' }} />

        <Grid container spacing={2} justifyContent="center" alignItems="center">
          <Grid
            size={6}
            sx={{
              '@media print': {
                width: '100% !important',
              },
            }}
          >
            <Table>
              {renderTableHead()}
              <OfferTable offer={offer} />
            </Table>
          </Grid>
          <Grid size={6} display="flex" justifyContent="center" alignItems="center">
            <ElectricityDemandGraph offer={offer} />
          </Grid>
        </Grid>
      </TabPanel>
      <TabPanel value={value} index={1}>
        {renderInfo()}

        <Divider variant="fullWidth" sx={{ marginTop: '30px', marginBottom: '40px' }} />

        <Grid container spacing={2} justifyContent="center" alignItems="center">
          <Grid size={6}>
            <Table>
              {renderTableHead()}
              <OfferEaasTable offer={offer} />
            </Table>
          </Grid>
          <Grid size={6} display="flex" justifyContent="center" alignItems="center">
            <OfferEaasGraph offer={offer} />
          </Grid>
        </Grid>
      </TabPanel>
      <TabPanel value={value} index={2}>
        {renderInfo()}

        <Divider variant="fullWidth" sx={{ marginTop: '30px', marginBottom: '40px' }} />

        <Grid container spacing={2} justifyContent="center" alignItems="center">
          <Grid
            size={6}
            sx={{
              '@media print': {
                width: '100% !important',
              },
            }}
          >
            <Table>
              {renderTableHead()}
              <OfferGcTable offer={offer} />
            </Table>
          </Grid>
          <Grid size={6} display="flex" justifyContent="center" alignItems="center">
            <OfferGcGraph offer={offer} />
          </Grid>
        </Grid>
      </TabPanel>
    </Box>
  );
};
