import { createTheme } from '@mui/material/styles';

export const theme = createTheme({
  typography: {
    fontFamily: 'Satoshi, sans-serif',
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: 'none',
          backgroundColor: '#000000',
          '&.Mui-disabled': {
            backgroundColor: '#71717A',
            color: 'rgba(255,255,255,0.7)',
          },
          '&:hover': {
            backgroundColor: '#494962',
          },
        },
        text: {
          backgroundColor: 'transparent',
          color: '#18181B',
          padding: '8px',
          minWidth: 'auto',
          boxShadow: 'none',
          '&:hover': {
            backgroundColor: '#F0F0F0',
            boxShadow: 'none',
          },
        },
        outlined: {
          backgroundColor: 'transparent',
          color: '#71717A',
          borderColor: '#71717A',
          '&:hover': {
            backgroundColor: '#494962',
            color: '#FFF',
          },
        },
      },
    },
    MuiStepLabel: {
      styleOverrides: {
        root: {
          '& .MuiStepLabel-iconContainer': {
            paddingRight: '0',
            paddingBottom: '16px',
            textAlign: 'center',
          }
        },
      },
    },
    MuiStepIcon: {
      styleOverrides: {
        root: {
          '&.Mui-active': {
            color: 'black',
          },
          '&.Mui-completed': {
            color: 'black',
          },
        },
      },
    },
    MuiInputAdornment: {
      styleOverrides:{
        root: {
          '& .MuiTypography-root': {
            color: '#A1A1AA',
          },
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          '&.Mui-disabled': {
            backgroundColor: '#F0F0F0',
          },
        },
      },
    },
    MuiSwitch: {
      styleOverrides: {
        track: {
          backgroundColor: '#fff',
          border: '1px solid #A1A1AA',
        },
        switchBase: {
          '&.Mui-checked': {
            '& + .MuiSwitch-track': {
              backgroundColor: '#fff',
            },
          },
          '& .MuiSwitch-thumb': {
            color: '#18181B',
          },
        },
      },
    },
  },
});
