import { FC } from 'react';
import { ErrorMessage, Field, useFormikContext } from 'formik';
import { MenuItem } from '@mui/material';
import Grid from '@mui/material/Grid2';
import { Input } from '@components/input';
import { CreateProjectForm } from '@pages/create-project/create-project';
import { useTranslation } from 'react-i18next';

export const FirstStep: FC = () => {
  const { t } = useTranslation();
  const {values, errors, touched} = useFormikContext<CreateProjectForm>();

  const orientations = [
    {value: 'S', label: 'S'},
    {value: 'SO/SW', label: 'SO/SW'},
    {value: 'O/W', label: 'O/W'},
  ];

  const inclinations = [
    {value: '0 Grad', label: '0 Grad'},
    {value: '45 Grad', label: '45 Grad'},
    {value: '90 Grad', label: '90 Grad'},
  ];

  return (
    <div className="flex flex-col">
      <Grid container spacing={4}>
        <Grid size={6}>
          <Field
            as={Input}
            label={t('project.projectName')}
            name="project_name"
            errors={errors}
            touched={touched}
            fullWidth
            isRequired
          />
        </Grid>

        <Grid size={6}>
          <Field
            as={Input}
            label={t('project.customerName')}
            name="customer_name"
            fullWidth
            errors={errors}
            touched={touched}
            helperText={<ErrorMessage name="customerName" />}
            isRequired
          />
        </Grid>

        <Grid size={4}>
          <Field
            as={Input}
            label={t('project.postalCode')}
            name="postal_code"
            fullWidth
            errors={errors}
            touched={touched}
            helperText={<ErrorMessage name="postalCode" />}
            isRequired
          />
        </Grid>

        <Grid size={4}>
          <Field
            as={Input}
            label={t('project.heatedArea')}
            name="heated_area"
            fullWidth
            type="number"
            adornment="M^2"
            errors={errors}
            touched={touched}
            helperText={<ErrorMessage name="heatedArea" />}
            isRequired
          />
        </Grid>

        <Grid size={4}>
          <Field
            as={Input}
            label={t('project.exteriorArea')}
            name="exterior_area"
            fullWidth
            type="number"
            adornment="M^2"
            errors={errors}
            touched={touched}
            helperText={<ErrorMessage name="exteriorArea" />}
            isRequired
          />
        </Grid>

        <Grid size={4}>
          <Field
            as={Input}
            label={t('project.roofArea')}
            name="roof_area"
            fullWidth
            type="number"
            adornment="M^2"
            errors={errors}
            touched={touched}
            helperText={<ErrorMessage name="roofArea" />}
            isRequired
          />
        </Grid>

        <Grid size={4}>
          <Field
            as={Input}
            select
            label={t('project.orientation')}
            name="orientation"
            value={values.orientation}
            errors={errors}
            touched={touched}
            fullWidth
            isRequired
          >
            {orientations.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {t(option.label)}
              </MenuItem>
            ))}
          </Field>
        </Grid>

        <Grid size={4}>
          <Field
            as={Input}
            select
            label={t('project.inclinationOfRoof')}
            name="inclination_of_roof"
            value={values.inclination_of_roof}
            fullWidth
            errors={errors}
            touched={touched}
            isRequired
          >
            {inclinations.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {t(option.label)}
              </MenuItem>
            ))}
          </Field>
        </Grid>
      </Grid>
    </div>
  );
};
