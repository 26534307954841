import React from 'react';
import { LogIn } from '@pages/log-in';
import { Home } from '@pages/home';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { Layout } from '@components/layout/layout';
import { CreateProject } from '@pages/create-project/create-project';

//fonts
import './fonts/Satoshi-Bold.otf';
import './fonts/Satoshi-BlackItalic.otf';
import './fonts/Satoshi-Black.otf';
import './fonts/Satoshi-BoldItalic.otf';
import './fonts/Satoshi-Italic.otf';
import './fonts/Satoshi-Light.otf';
import './fonts/Satoshi-LightItalic.otf';
import './fonts/Satoshi-Medium.otf';
import './fonts/Satoshi-MediumItalic.otf';
import './fonts/Satoshi-Regular.otf';

function App() {
  return (
    <Router>
      <Layout>
        <Routes>
          <Route path="/" element={<Home/>}/>
          <Route path="/login" element={<LogIn/>}/>
          <Route path="/project-new" element={<CreateProject/>}/>
        </Routes>
      </Layout>
    </Router>
  );
}

export default App;
