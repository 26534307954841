import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import './core/i18n';
import App from './App';
import { CssBaseline } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import { theme } from './core/theme';
import { queryClient } from './core/client';
import { QueryClientProvider } from '@tanstack/react-query';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <CssBaseline/>
      <QueryClientProvider client={queryClient}>
        <App/>
      </QueryClientProvider>
    </ThemeProvider>
  </React.StrictMode>
);
