import { FC, SyntheticEvent } from 'react';
import { Tabs, Tab } from '@mui/material';

interface TabsComponentProps {
  value: number;
  handleChange: (event: SyntheticEvent, newValue: number) => void;
  tabLabels: string[];
}

export const TabsComponent: FC<TabsComponentProps> = ({ value, handleChange, tabLabels }) => {
  return (
    <Tabs
      value={value}
      onChange={handleChange}
      aria-label="offer tabs"
      textColor="primary"
      variant="fullWidth"
      sx={{
        '.MuiTabs-indicator': {
          backgroundColor: '#000000',
        },
      }}
    >
      {tabLabels.map((label, index) => (
        <Tab
          key={index}
          label={label}
          sx={{
            padding: 0,
            backgroundColor: value === index ? 'white' : '#A1A1AA80',
            fontWeight: 'bold',
            transition: 'background-color 0.3s',
            borderRight: index !== tabLabels.length - 1 ? '1px solid #A1A1AA80' : 'none',
            borderLeft: index !== 0 ? '1px solid #A1A1AA80' : 'none',
            borderBottom: '1px solid #A1A1AA80',
            borderTopLeftRadius: index === 0 ? '8px' : '0px',
            borderTopRightRadius: index === tabLabels.length - 1 ? '8px' : '0px',
            '&.MuiTab-root.Mui-selected': {
              color: '#18181B'
            },
          }}
        />
      ))}
    </Tabs>
  );
};
