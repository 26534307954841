import { FC, ReactNode } from 'react';

interface CenterWrapperProps {
  children: ReactNode;
}

export const Container: FC<CenterWrapperProps> = ({ children }) => {
  return (
    <div className="flex justify-center w-full">
      <div className="relative w-full max-w-max flex-1 bg-white px-20 py-10">
        {children}
      </div>
    </div>
  );
};
