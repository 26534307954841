import React, { FC } from "react";
import {
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  LineChart,
  Line
} from 'recharts';
import { ProjectResponse } from '@api/projects/mutations';
import { formatValue } from '@components/create-project/table/helpers';
import { CircularProgress } from '@mui/material';

interface OfferGraphProps {
  offer: ProjectResponse | null;
}

export const OfferEaasGraph: FC<OfferGraphProps> = ({ offer }) => {
  if (!offer) return <CircularProgress size={100}/>;

  const case1Data = offer.outputs['Offer E-a-a-S']['Case 1']['End of Offer to Customer'];
  const case2Data = offer.outputs['Offer E-a-a-S']['Case 2']['End of Offer to Customer'];
  const case3Data = offer.outputs['Offer E-a-a-S']['Case 3']['End of Offer to Customer'];


  const data = case1Data.map((_, index) => ({
    year: `Y ${index}`,
    "Case 1": case1Data[index],
    "Case 2": case2Data[index],
    "Case 3": case3Data[index],
  }));

  return (

      <ResponsiveContainer className="print:hidden" width="100%" style={{ paddingTop: '34px' }} height={500}>
        <LineChart data={data} margin={{ top: 20, right: 30, left: 20, bottom: 5 }}>
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="year" />
          <YAxis
            tickFormatter={(value) => `${value} EUR`}
          />
          <Tooltip formatter={(value: number, name: string) => [`${formatValue(value)} EUR`, name]} />
          <Legend />
          <Line type="monotone" dataKey="Case 1" strokeWidth={4} stroke="#9DD4D9" dot={false} />
          <Line type="monotone" dataKey="Case 2" strokeWidth={4} stroke="#98DCA7" dot={false} />
          <Line type="monotone" dataKey="Case 3" strokeWidth={4} stroke="#D5EA64" dot={false} />
          <text
            x="50%"
            y={8}
            textAnchor="middle"
            dominantBaseline="middle"
            style={{ fontSize: '14px', fill: '#18181B', fontWeight: 500 }}
          >
            Offer E-a-a-S: Total Cost on 20y Horizon
          </text>
        </LineChart>
      </ResponsiveContainer>
  );
};
