import { FC } from 'react';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useNavigate } from 'react-router-dom';

export const BackToHome: FC = () => {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate('/');
  };

  return (
    <div className="absolute left-20 top-10 cursor-pointer hover:bg-gray-200 p-2 rounded-md" onClick={handleClick}>
      <ArrowBackIcon />
    </div>
  );
};
