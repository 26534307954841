import React, { FC, ReactNode } from 'react';
import { Box, Step, StepLabel, Stepper } from '@mui/material';
import useStepper from '@hooks/use-stepper';
import { Button } from '@components/ui-kit/button';
import { useFormikContext } from 'formik';
import { CreateProjectForm } from '@pages/create-project/create-project';
import { BackToHome } from '@components/layout/back-to-home';
import { UseMutateFunction } from '@tanstack/react-query';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';

interface StepperWrapperProps {
  steps: (string | ReactNode)[];
  stepsContent: ReactNode[];
  stepFields: string[][];
  calculateOffer: UseMutateFunction<any, Error, CreateProjectForm, unknown>;
  isLoading: boolean;
}

export const CreateProjectStepper: FC<StepperWrapperProps> = ({
                                                                steps,
                                                                stepsContent,
                                                                stepFields,
                                                                calculateOffer,
                                                                isLoading
                                                              }) => {
  const { t } = useTranslation();

  const {
    values,
    validateForm,
    setFieldTouched,
    submitForm
  } = useFormikContext<CreateProjectForm>();
  const {activeStep, isLastStep, handleNext, handleBack} = useStepper({steps});

  const handleClickNext = async () => {
    const currentFields = stepFields[activeStep];
    const validationErrors = await validateForm();

    // Check if any of the current step fields have validation errors
    const currentStepErrors = currentFields.some((field) => {
      const fieldKey = field as keyof CreateProjectForm;
      return validationErrors[fieldKey];
    });

    if (currentStepErrors) {
      // Mark all current step fields as touched to show validation errors
      currentFields.forEach((field) => setFieldTouched(field, true, true));

      toast.error('Please fill in all required fields for this step.');
    } else {
      if (activeStep === 1) {
        calculateOffer(values);
      }
      handleNext();
    }
  };

  const handleExportPdf = () => {
    window.print();
  };

  return (
    <>
      <div className="flex flex-col items-center justify-center print:hidden">
        <BackToHome/>
        <span className="text-[34px] font-bold mb-2">
          {isLastStep ? 'Offer to customer' : 'Create new project'}
       </span>
        <span className="">
           {isLastStep
             ? 'Please review our offers and choose the ones that suit you'
             : 'Please fill in all required fields for the first step, after that you can continue'}
      </span>
      </div>
      <div className="flex justify-center">
        <Box className="flex bg-gray-100 justify-center rounded-mid" sx={{width: '100%', py: '40px', mt: '40px'}}>
          <Box sx={{width: '94%'}}>
            <Stepper sx={{ displayPrint: 'none' }} activeStep={activeStep} className="mb-10">
              {steps.map((label, index) => (
                <Step sx={{width: '164px'}} key={index}>
                  <StepLabel className="flex flex-col text-center max-w-[164px">
                    {label}
                  </StepLabel>
                </Step>
              ))}
            </Stepper>

            <Box sx={{mt: 2, mb: '100px'}}>
              <>{stepsContent[activeStep]}</>
            </Box>

            {/* Navigation buttons */}
            <Box className="flex justify-end print:hidden">
              {activeStep !== 0 && (
                <Button
                  variant="outlined"
                  disabled={activeStep === 0}
                  onClick={handleBack}
                  sx={{width: '141px', mr: '32px', fontWeight: 900}}
                >
                  {t('stepper.buttons.back')}
                </Button>
              )}
              <Box/>
              <Button
                variant={isLastStep ? 'outlined' : 'contained'}
                color="primary"
                disabled={isLoading || values.calculateSeparately}
                onClick={isLastStep ? handleExportPdf : handleClickNext}
                sx={{width: '266px', fontWeight: 900}}
              >
                {isLastStep
                  ? t('stepper.buttons.exportPdf')
                  : activeStep === 1
                    ? t('stepper.buttons.startCalculation')
                    : t('stepper.buttons.next')}
              </Button>
              {isLastStep ?
                <Button
                  variant="contained"
                  color="primary"
                  disabled={isLoading}
                  onClick={submitForm}
                  sx={{width: '266px', ml: '32px', fontWeight: 900}}
                >
                  {t('stepper.buttons.saveProject')}
                </Button>
                : null
              }
            </Box>
          </Box>
        </Box>
      </div>
    </>
  );
};
