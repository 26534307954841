import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import enJson from './translations/en.json';
import deJson from './translations/de.json';

i18n
  .use(initReactI18next)
  .init({
    fallbackLng: 'de',
    interpolation: {
      escapeValue: false
    },
    resources: {
      en: {
        translation: enJson
      },
      de: {
        translation: deJson
      }
    }
  });

export default i18n;
