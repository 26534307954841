import { FC, useEffect, useMemo, useRef, useState } from 'react';
import { Paper } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { DataGrid, GridColDef, GridPaginationModel } from '@mui/x-data-grid';
import { TableControl } from '@components/table-control';
import { Container } from '@components/layout/container';
import { Button } from '@components/ui-kit/button';
import { formatDate } from '@utils/date-utils';
import { useProjectsQuery } from '@api/projects/requests';

import BorderColorOutlinedIcon from '@mui/icons-material/BorderColorOutlined';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import OpenInNewOutlinedIcon from '@mui/icons-material/OpenInNewOutlined';


export const Home: FC = () => {
  const navigate = useNavigate();
  const [searchValue, setSearchValue] = useState<string>('');
  const [debouncedSearchValue, setDebouncedSearchValue] = useState<string>(searchValue);
  const [paginationModel, setPaginationModel] = useState<GridPaginationModel>({
    page: 0,
    pageSize: 10,
  });

  const { data, isLoading } = useProjectsQuery({
    limit: paginationModel.pageSize,
    page: paginationModel.page + 1,
    search: debouncedSearchValue,
  });

  const rowCountRef = useRef(data?.elements_total || 0);

  const rowCount = useMemo(() => {
    if (data?.elements_total !== undefined) {
      rowCountRef.current = data.elements_total;
    }
    return rowCountRef.current;
  }, [data?.elements_total]);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedSearchValue(searchValue);
    }, 1000);

    return () => {
      clearTimeout(handler);
    };
  }, [searchValue]);

  const handleEdit = (id: number) => {
    console.log(`Edit project with id: ${id}`);
  };

  const handleDelete = (id: number) => {
    console.log(`Delete project with id: ${id}`);
  };

  const handleOpen = (id: number) => {
    console.log(`Open project with id: ${id}`);
  };

  const handleGoToCreateProject = () => {
    navigate("/project-new");
  }

  const columns: GridColDef[] = [
    {field: 'project_name', headerClassName: 'table-header', headerName: 'Project name', flex: 1},
    {field: 'customer_name', headerClassName: 'table-header', headerName: 'Customer name', flex: 1},
    {field: 'postal_code', headerClassName: 'table-header', headerName: 'Postal Code', flex: 1},
    {
      field: 'created_at',
      headerClassName: 'table-header',
      headerName: 'Date added',
      flex: 1,
      valueFormatter: (value) => formatDate(new Date(value)),
    },
    {
      field: 'settings',
      headerClassName: 'table-header',
      headerName: 'Settings',
      flex: 1,
      renderCell: (params) => (
        <div className="flex space-x-2">
          <Button
            className="flex h-fit self-center"
            variant="text"
            color="primary"
            size="small"
            onClick={() => handleEdit(params.row.id)}
          >
            <BorderColorOutlinedIcon className="text-metallic hover:text-black-800" />
          </Button>
          <Button
            className="flex h-fit self-center"
            variant="text"
            color="secondary"
            size="small"
            onClick={() => handleDelete(params.row.id)}
          >
            <DeleteOutlineOutlinedIcon className="text-metallic hover:text-black-800" />
          </Button>
          <Button
            className="flex h-fit self-center"
            variant="text"
            size="small"
            onClick={() => handleOpen(params.row.id)}
          >
            <OpenInNewOutlinedIcon className="text-metallic hover:text-black-800" />
          </Button>
        </div>
      )
    }
  ];

  return (
    <Container>
      <TableControl
        name="Dashboard"
        count={data?.elements_total}
        countName="Projects"
        inputPlaceholder="Project name"
        search={searchValue}
        onSearch={setSearchValue}
        buttonText="Create project"
        buttonClickFn={handleGoToCreateProject}
      />
      <Paper className="bg-gray-100" sx={{ backgroundColor: '#FAFAFA' }}>
        <DataGrid
          rows={data?.projects || []}
          columns={columns}
          rowCount={rowCount}
          pagination
          paginationMode="server"
          paginationModel={paginationModel}
          onPaginationModelChange={setPaginationModel}
          pageSizeOptions={[10, 20]}
          localeText={{ noRowsLabel: "No results" }}
          disableColumnMenu
          disableColumnResize
          loading={isLoading}
          rowSelection={false}
          sx={{
            maxHeight: '629px',
            height: '629px',
            border: 0,
            '& .MuiDataGrid-main': {
              px: '24px',
            },
            '& .MuiDataGrid-columnSeparator': {
              display: 'none',
            },
            '& .table-header': {
              backgroundColor: '#FAFAFA',
            },
            '& .table-header-id': {
              backgroundColor: '#FAFAFA',
              pl: '40px',
            },
            '& .table-cell-id': {
              pl: '40px',
            },
            '& .MuiDataGrid-cell': {
              display: 'flex',
            },
            '& .MuiDataGrid-row:hover': {
              backgroundColor: '#FFFFFF',
            },
            '& .MuiDataGrid-footerContainer': {
              backgroundColor: '#FFFFFF',
            },
            mb: '40px',
          }}
        />
      </Paper>
    </Container>
  );
};
