import { FC } from 'react';
import { Button, TextField } from '@mui/material';

interface TableControlProps {
  name: string;
  count: number;
  countName: string;
  inputPlaceholder: string;
  buttonText?: string;
  buttonClickFn?: () => void;
  search: string;
  onSearch: (value: string) => void;
}

export const TableControl: FC<TableControlProps> = ({name, count, countName, buttonText, inputPlaceholder, search, onSearch, buttonClickFn}) => {

  return (
    <div className="flex mb-10 justify-between items-center h-14">
      <div className="flex items-center">
        <span className="text-[34px] font-bold border-r mr-5 pr-5 ">
          {name}
        </span>
        <span className="text-metallic">
          {count} {countName}
        </span>
      </div>
      <div className="h-full">
        <TextField
          className="w-96"
          value={search}
          placeholder={inputPlaceholder}
          label="Search"
          type="text"
          onChange={(e) => onSearch(e.target.value)}
        />
        <Button onClick={buttonClickFn} sx={{ml: 10}} className="w-48 h-full" variant="contained">{buttonText}</Button>
      </div>
    </div>
  );
};
