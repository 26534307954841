import React, { FC, useEffect } from 'react';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useLocation, useNavigate } from 'react-router-dom';
import { Header } from '@components/layout/header';

interface LayoutProps {
  children: React.ReactNode;
}

export const Layout: FC<LayoutProps> = ({children}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const user = localStorage.getItem('user');

  useEffect(() => {
    authCheck();
  }, [location.pathname, user]);

  const authCheck = () => {
    if (location.pathname === '/login') {
      if (user) {
        navigate('/');
      }
    } else {
      if (!user) {
        navigate('/login');
      }
    }
  };

  return (
    <div className="flex flex-col w-full min-h-screen">
      <ToastContainer
        position="bottom-left"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      <Header />
      {children}
    </div>
  );
};
