import * as yup from 'yup';
import i18n from '@core/i18n';

export const createProjectSchema = yup.object().shape({
  project_name: yup.string().required(i18n.t('project.errors.required')),
  customer_name: yup.string().required(i18n.t('project.errors.required')),
  postal_code: yup.string().required(i18n.t('project.errors.required')),
  heated_area: yup.number().required(i18n.t('project.errors.required')).min(1, i18n.t('project.errors.shouldBeGreater')),
  exterior_area: yup.number().required(i18n.t('project.errors.required')),
  roof_area: yup.number().required(i18n.t('project.errors.required')).min(1, i18n.t('project.errors.shouldBeGreater')),
  orientation: yup.string().required(i18n.t('project.errors.required')),
  inclination_of_roof: yup.string().required(i18n.t('project.errors.required')),
  with_thermal_renovation: yup.boolean(),
  calculateSeparately: yup.boolean(),

  // For calculateSeparately = false, these fields are required
  heating_performance_total: yup.number().when('calculateSeparately', ([calculateSeparately], schema) => {
    return calculateSeparately ? schema.notRequired() : schema.required('Heating performance total is required');
  }),
  heating_consumption_total: yup.number().when('calculateSeparately', ([calculateSeparately], schema) => {
    return calculateSeparately ? schema.notRequired() : schema.required('Heating consumption total is required');
  }),

  // For calculateSeparately = true, these fields are required
  heating_power: yup.number().when('calculateSeparately', ([calculateSeparately], schema) => {
    return calculateSeparately ? schema.required('Heating power is required') : schema.notRequired();
  }),
  heating_consumption: yup.number().when('calculateSeparately', ([calculateSeparately], schema) => {
    return calculateSeparately ? schema.required('Heating consumption is required') : schema.notRequired();
  }),
  warm_water_power: yup.number().when('calculateSeparately', ([calculateSeparately], schema) => {
    return calculateSeparately ? schema.required('Warm water power is required') : schema.notRequired();
  }),
  warm_water_consumption: yup.number().when('calculateSeparately', ([calculateSeparately], schema) => {
    return calculateSeparately ? schema.required('Warm water consumption is required') : schema.notRequired();
  }),
  temperature_conditioning_power: yup.number().when('calculateSeparately', ([calculateSeparately], schema) => {
    return calculateSeparately ? schema.required('Temperature conditioning power is required') : schema.notRequired();
  }),
  temperature_conditioning_consumption: yup.number().when('calculateSeparately', ([calculateSeparately], schema) => {
    return calculateSeparately ? schema.required('Temperature conditioning consumption is required') : schema.notRequired();
  }),
});
