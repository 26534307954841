import React, { FC } from 'react';
import { Switch, FormControlLabel, FormGroup } from '@mui/material';
import { FormikErrors } from 'formik/dist/types';
import { FormikValues } from 'formik';

interface SwitchComponentProps {
  name: string;
  leftLabel?: string;
  rightLabel?: string;
  checked: boolean;
  setChecked: (name: string, value: boolean) => Promise<void | FormikErrors<FormikValues>>;
}

export const SwitchComponent: FC<SwitchComponentProps> = ({
  name,
  leftLabel,
  rightLabel,
  checked,
  setChecked,
}) => {
  const onChange = () => {
    setChecked(name, !checked);
  };

  const leftLabelClick = () => {
    setChecked(name, false);
  };

  const rightLabelClick = () => {
    setChecked(name, true);
  };


  return (
    <FormGroup row>
      {leftLabel ? <FormControlLabel
        control={<></>}
        label={leftLabel}
        onClick={leftLabelClick}
        labelPlacement="start"
        sx={{
          marginRight: '8px',
          '.MuiFormControlLabel-label': {
            fontSize: '14px',
            fontWeight: 500,
          },
        }}
      /> : null}
      <Switch
        checked={checked}
        onChange={onChange}
      />
      {rightLabel ? <FormControlLabel
        control={<></>}
        label={rightLabel}
        onClick={rightLabelClick}
        sx={{
          marginLeft: '8px',
          '.MuiFormControlLabel-label': {
            fontSize: '14px',
            fontWeight: 500,
          },
        }}
      /> : null}
    </FormGroup>
  );
};
