import React, { HTMLInputTypeAttribute } from 'react';
import { InputAdornment, TextField } from '@mui/material';
import { ErrorMessage } from 'formik';
import { FormikErrors } from 'formik/dist/types';

interface InputProps {
  className?: string;
  value: string | number;
  label: string;
  type?: HTMLInputTypeAttribute;
  isRequired?: boolean;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  errors?: FormikErrors<any>;
  helperText?: string;
  fullWidth?: boolean;
  placeholder?: string;
  adornment?: string;
  name: string;
  touched: {
    [key: string]: boolean;
  };
}

export const Input: React.FC<InputProps> = ({
                                              value,
                                              onChange,
                                              label,
                                              type = 'text',
                                              isRequired = false,
                                              className,
                                              errors,
                                              helperText,
                                              fullWidth = false,
                                              placeholder,
                                              adornment,
                                              name,
                                              touched,
                                              ...rest
                                            }) => {
  const isError = !!(touched?.[name] && errors?.[name]);

  const displayValue = type === 'number' && !isNaN(Number(value))
    ? value === "" ? "" : Math.round(+value)
    : value;

  return (
    <TextField
      className={className}
      label={label}
      name={name}
      type={type}
      value={displayValue}
      onChange={onChange}
      fullWidth={fullWidth}
      placeholder={placeholder}
      variant="outlined"
      required={isRequired}
      error={isError}
      helperText={isError && <ErrorMessage name={name}/>}
      InputProps={{
        endAdornment: adornment ? (
          <InputAdornment position="end">{adornment}</InputAdornment>
        ) : null,
        inputProps: {
          inputMode: 'numeric',
          pattern: type === 'number' ? '+[0-9]*' : undefined,
        },
      }}
      {...rest}
      sx={{
        '& input[type=number]': {
          MozAppearance: 'textfield',
          '&::-webkit-outer-spin-button, &::-webkit-inner-spin-button': {
            WebkitAppearance: 'none',
            margin: 0,
          },
        },
      }}
    />
  );
};
