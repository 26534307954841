import { SyntheticEvent, useState } from 'react';

export const useTabs = (initialIndex: number) => {
  const [value, setValue] = useState(initialIndex);

  const handleChange = (event: SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  return { value, handleChange };
};
