import React, { FC } from "react";
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer, Cell } from 'recharts';
import { ProjectResponse } from '@api/projects/mutations';
import { formatValue } from '@components/create-project/table/helpers';
import { CircularProgress } from '@mui/material';

interface OfferGraphProps {
  offer: ProjectResponse | null;
}

export const ElectricityDemandGraph: FC<OfferGraphProps> = ({ offer }) => {

  if (!offer) return <CircularProgress size={100}/>;

  const data = [
    { case: "Case 1", "Total Electricity Demand": formatValue(offer.outputs.Offer['Case 1']['Total electricity demand from grid']) },
    { case: "Case 2", "Total Electricity Demand": formatValue(offer.outputs.Offer['Case 2']['Total electricity demand from grid']) },
    { case: "Case 3", "Total Electricity Demand": formatValue(offer.outputs.Offer['Case 3']['Total electricity demand from grid']) }
  ];

  return (
    <ResponsiveContainer className="print:hidden" width="100%" height={550}>
      <BarChart data={data} margin={{ top: 20, right: 30, left: 20, bottom: 5 }}>
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="case" />
        <YAxis
          tickFormatter={(value) => `${value} MWh/a`}
        />
        <Tooltip formatter={(value: number, name: string) => [`${formatValue(value)} MWh/a`, name]} />
        <defs>
          <linearGradient id="gradient1" x1="0%" y1="0%" x2="0%" y2="100%">
            <stop offset="0%" stopColor="#E3F38D" stopOpacity={1} />
            <stop offset="100%" stopColor="#F4FCCA" stopOpacity={1} />
          </linearGradient>
          <linearGradient id="gradient2" x1="0%" y1="0%" x2="0%" y2="100%">
            <stop offset="0%" stopColor="#98DCA7" stopOpacity={1} />
            <stop offset="100%" stopColor="#CBEFD3" stopOpacity={1} />
          </linearGradient>
          <linearGradient id="gradient3" x1="0%" y1="0%" x2="0%" y2="100%">
            <stop offset="0%" stopColor="#9DD4D9" stopOpacity={1} />
            <stop offset="100%" stopColor="#C5F2F6" stopOpacity={1} />
          </linearGradient>
        </defs>
        <Bar dataKey="Total Electricity Demand">
          {data.map((entry, index) => (
            <Cell key={`cell-${index}`} fill={`url(#gradient${index + 1})`} />
          ))}
        </Bar>
        <text
          x="50%"
          y={8}
          textAnchor="middle"
          dominantBaseline="middle"
          style={{ fontSize: '14px', fill: '#18181B', fontWeight: 500 }}
        >
          Total electricity demand from grid
        </text>
      </BarChart>
    </ResponsiveContainer>
  );
};
