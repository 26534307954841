import { OfferDetails, OfferEaasDetails, OfferGCDetails, ProjectResponse } from '@api/projects/mutations';
import { TableCell, TableRow } from '@mui/material';
import React from 'react';

interface RenderRowProps {
  offer: ProjectResponse | null;
  label: string;
  name: keyof OfferDetails | keyof OfferEaasDetails | keyof OfferGCDetails;
  caseType: 'Offer' | 'Offer E-a-a-S' | 'Offer GC';
  units?: string;
  underline?: boolean;
  fontStyle?: string;
  fontWeight?: number;
}

export const formatValue = (value: string | number) => {
  if (typeof value === 'number') {

    // Handle very small numbers to prevent rounding to 0
    if (Math.abs(value) < 0.01 && value !== 0) {
      return value.toExponential(2); // Use scientific notation for very small numbers
    }

    return value.toLocaleString('en-US', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
  }

  return value;
};

export const RenderRow: React.FC<RenderRowProps> = ({
                                                      offer,
                                                      name,
                                                      label,
                                                      caseType,
                                                      units,
                                                      underline,
                                                      fontStyle,
                                                      fontWeight = 400
                                                    }) => {

  const caseData =
    caseType === 'Offer'
      ? offer?.outputs.Offer
      : caseType === 'Offer E-a-a-S'
        ? offer?.outputs['Offer E-a-a-S']
        : offer?.outputs['Offer GC'];

  return (
    <TableRow sx={underline ? { borderBottom: '2px solid black' } : null}>
      <TableCell sx={{ padding: '6px 12px', fontStyle: fontStyle, fontWeight }}>
        {label}
      </TableCell>
      <TableCell sx={{
        padding: '6px 12px',
        textAlign: 'right',
        fontWeight: fontWeight,
        fontStyle: fontStyle
      }}>
        {offer && caseData
          ? formatValue(caseData['Case 1'][name as keyof typeof caseData['Case 1']])
          : null}
      </TableCell>
      <TableCell sx={{
        padding: '6px 12px',
        textAlign: 'right',
        fontWeight: fontWeight,
        fontStyle: fontStyle
      }}>
        {offer && caseData
          ? formatValue(caseData['Case 2'][name as keyof typeof caseData['Case 2']])
          : null}
      </TableCell>
      <TableCell sx={{
        padding: '6px 12px',
        textAlign: 'right',
        fontWeight: fontWeight,
        fontStyle: fontStyle
      }}>
        {offer && caseData
          ? formatValue(caseData['Case 3'][name as keyof typeof caseData['Case 3']])
          : null}
      </TableCell>
      {units && <TableCell sx={{ padding: '8px 0', fontStyle: fontStyle, fontWeight }}>{units}</TableCell>}
    </TableRow>
  );
};

export const EmptyRow: React.FC = () => {

  return (
    <TableRow>
      <TableCell />
      <TableCell />
      <TableCell />
      <TableCell />
      <TableCell />
    </TableRow>
  );
};

export const renderCheckRow = (
  offer: ProjectResponse | null,
  name: string,
) => {
  if (!offer) {
    return null;
  }

  const cases: Array<keyof typeof offer.outputs.Offer> = ['Case 1', 'Case 2', 'Case 3'];

  return (
    <TableRow>
      <TableCell sx={{padding: '6px 12px'}}>{name}</TableCell>
      {cases.map(caseName => {
        const value = offer?.outputs.Offer[caseName]['CHECK Exterior area vs. Probes field area'];
        const isNotOk = value === 'NOT OK!';

        return (
          <TableCell key={caseName} sx={{padding: '6px 12px', textAlign: 'right'}}>
            <span className={`${isNotOk ? 'font-bold text-red-500' : 'font-medium italic'}`}>
              {value}
            </span>
          </TableCell>
        );
      })}
      <TableCell sx={{padding: '8px 0'}}></TableCell>
    </TableRow>
  );
};
