import React, { FC } from 'react';
import { TableBody } from '@mui/material';
import { ProjectResponse } from '@api/projects/mutations';
import { EmptyRow, renderCheckRow, RenderRow } from '@components/create-project/table/helpers';
import { useTranslation } from 'react-i18next';

interface OfferTableProps {
  offer: ProjectResponse;
}

export const OfferTable: FC<OfferTableProps> = ({ offer }) => {
  const { t } = useTranslation();

  return (
    <TableBody>
      <RenderRow
        offer={offer}
        label={t('project.table.case1.electricalPowerHP')}
        name={'Electrical power HP'}
        caseType='Offer'
        units='kW'
      />
      <RenderRow
        offer={offer}
        label={t('project.table.case1.electricalPowerAHEx')}
        name="Electrical power AHEx"
        caseType="Offer"
        units="kW"
      />
      <RenderRow
        offer={offer}
        label={t('project.table.case1.electricalPowerAHP')}
        name="Electrical power AHP"
        caseType="Offer"
        units="kW"
      />
      <RenderRow
        offer={offer}
        label={t('project.table.case1.pvUsedRoofArea')}
        name="PV used roof area"
        caseType="Offer"
        units="m2"
      />
      <RenderRow
        offer={offer}
        label={t('project.table.case1.pvPerformance')}
        name="PV performance"
        caseType="Offer"
        units="kWp"
      />
      <RenderRow
        offer={offer}
        label={t('project.table.case1.probesLengthTotal')}
        name="Probes length total"
        caseType="Offer"
        units="m"
      />
      <RenderRow
        offer={offer}
        label={t('project.table.case1.probesFieldArea')}
        name="Probes field area"
        caseType="Offer"
        units="m2"
      />
      {renderCheckRow(offer, t('project.table.case1.checkExteriorAreaVsProbesFieldArea'))}
      <EmptyRow />
      <RenderRow
        offer={offer}
        label={t('project.table.case1.totalPowerRequirement')}
        name="Total Power requirement"
        caseType="Offer"
        units="MWh/a"
      />
      <RenderRow
        offer={offer}
        label={t('project.table.case1.pvYieldTotal')}
        name="PV yield total"
        caseType="Offer"
        units="MWh/a"
        underline
      />
      <RenderRow
        offer={offer}
        label={t('project.table.case1.totalElectricityDemandFromGrid')}
        name="Total electricity demand from grid"
        caseType="Offer"
        units="MWh/a"
        fontWeight={700}
      />
    </TableBody>
  );
};
