import React, { FC } from 'react';
import { TableBody } from '@mui/material';
import { ProjectResponse } from '@api//projects/mutations';
import { EmptyRow, RenderRow } from '@components/create-project/table/helpers';
import { useTranslation } from 'react-i18next';

interface OfferGcTableProps {
  offer: ProjectResponse;
}

export const OfferGcTable: FC<OfferGcTableProps> = ({ offer }) => {
  const { t } = useTranslation();

  return (
    <TableBody>
      <RenderRow
        offer={offer}
        label={t('project.table.case3.tic')}
        name="TIC"
        caseType="Offer GC"
        units="EUR"
      />
      <RenderRow
        offer={offer}
        label={t('project.table.case3.subsidyPublicGrant')}
        name="Subsidy/public grant"
        caseType="Offer GC"
        units="EUR"
      />
      <RenderRow
        offer={offer}
        label={t('project.table.case3.gcMargin')}
        name="GC margin"
        caseType="Offer GC"
        units="EUR"
        underline
      />
      <RenderRow
        offer={offer}
        label={t('project.table.case3.totalCostToCustomer')}
        name="Total Cost to customer"
        caseType="Offer GC"
        units="EUR"
        fontWeight={700}
      />
      <RenderRow
        offer={offer}
        label={t('project.table.case3.tccPM2')}
        name="TCC p.m2"
        caseType="Offer GC"
        units="EUR/m2"
        fontStyle="italic"
      />
      <RenderRow
        offer={offer}
        label={t('project.table.case3.tccPWE')}
        name="TCC p.WE"
        caseType="Offer GC"
        units="EUR/WE"
        fontStyle="italic"
      />
      <EmptyRow />
      <RenderRow
        offer={offer}
        label={t('project.table.case3.averageOpexTotal')}
        name="Average OPEX total (HEAT+WW+TEMP)"
        caseType="Offer GC"
        units="EUR/a"
      />
      <RenderRow
        offer={offer}
        label={t('project.table.case3.averageOpexSpecificPA')}
        name="Average OPEX specific p.a. (HEAT+WW+TEMP)"
        caseType="Offer GC"
        units="EUR/m2*a"
      />
      <RenderRow
        offer={offer}
        label={t('project.table.case3.averageOpexSpecificPM')}
        name="Average OPEX specific p.m. (HEAT+WW+TEMP)"
        caseType="Offer GC"
        units="EUR/m2*m"
        fontStyle="italic"
      />
      <EmptyRow />
      <RenderRow
        offer={offer}
        label={t('project.table.case3.totalCost20yHorizon')}
        name="Total cost on 20y horizon"
        caseType="Offer GC"
        units="EUR"
        fontWeight={700}
      />
    </TableBody>
  );
};
