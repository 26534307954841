import { FC } from 'react';
import { Form, Formik } from 'formik';
import { Container } from '@components/layout/container';
import { CreateProjectStepper } from '@components/create-project/stepper';
import { FirstStep } from '@pages/create-project/first-step';
import { SecondStep } from '@pages/create-project/second-step';
import { FinalStep } from '@pages/create-project/final-step';
import { createProjectSchema } from '@pages/create-project/create-project.schema';
import { useCalculationMutation, useCreateProjectMutation, useOfferCalculationMutation } from '@api/projects/mutations';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';

export interface CreateProjectForm {
  project_name: string;
  customer_name: string;
  postal_code: string;
  heated_area: number | '';
  exterior_area: number | '';
  roof_area: number | '';
  orientation: string;
  inclination_of_roof: string;
  with_thermal_renovation: boolean;
  calculateSeparately: boolean;
  heating_performance_total: number | '';
  heating_consumption_total: number | '';
  heating_power: number | '';
  heating_consumption: number | '';
  warm_water_power: number | '';
  warm_water_consumption: number | '';
  temperature_conditioning_power: number | '';
  temperature_conditioning_consumption: number | '';
  energy_price_consumer_heat_and_ww_net: number;
  energy_price_consumer_temp_net: number;
  subsidy_public_grant_on_tic: number;
  electricity_price_index_vs_cpi: number;
}

export const CreateProject: FC = () => {
  const navigate = useNavigate();

  const { mutate: calculateBuildingPerformance, isPending: isCalculationLoading} = useCalculationMutation();

  const { data: offer, mutate: calculateOffer, isPending: isOfferCalculating } = useOfferCalculationMutation();

  const { mutate: createProject, isPending: isProjectCreating } = useCreateProjectMutation();

  const initialValues: CreateProjectForm = {
    project_name: '',
    customer_name: '',
    postal_code: '',
    heated_area: '',
    exterior_area: '',
    roof_area: '',
    orientation: '',
    inclination_of_roof: '',
    with_thermal_renovation: false,
    calculateSeparately: false,
    heating_performance_total: '',
    heating_consumption_total: '',
    heating_power: '',
    heating_consumption: '',
    warm_water_power: '',
    warm_water_consumption: '',
    temperature_conditioning_power: '',
    temperature_conditioning_consumption: '',
    energy_price_consumer_heat_and_ww_net: 1920,
    energy_price_consumer_temp_net: 240,
    subsidy_public_grant_on_tic: 25,
    electricity_price_index_vs_cpi: 88,
  };

  const steps = [
    <>
      Location,<br/>dimensions and usage
    </>,
    <>
      Power <br/>and consumption
    </>,
    'Offer'
  ];

  const handleCreateProject = () => {
    try {
      createProject(offer, {
        onSuccess: () => {
          toast.success('Project created');
          navigate('/');
        },
        onError: () => toast.error('Something went wrong')
      })
    } catch {}
  }

  const stepFields = [
    ['project_name', 'customer_name', 'postal_code', 'exterior_area', 'heated_area', 'roof_area', 'orientation', 'inclination_of_roof'],
    [
      'heating_performance_total', 'heating_consumption_total', 'heating_power', 'heating_consumption', 'warm_water_power',
      'warm_water_consumption', 'temperature_conditioning_power', 'temperature_conditioning_consumption'
    ],
  ];

  const handleSubmit = (values: CreateProjectForm) => {
    console.log('Form submitted:', values);
    handleCreateProject();
  };

  const renderStepperContent = () => [
    <FirstStep />,
    <SecondStep calculateBuildingPerformance={calculateBuildingPerformance} />,
    <FinalStep offer={offer} calculateOffer={calculateOffer} />,
  ];

  return (
    <Container>
      <Formik
        initialValues={initialValues}
        validationSchema={createProjectSchema}
        onSubmit={handleSubmit}
      >
        {() => (
          <Form>
            <CreateProjectStepper
              steps={steps}
              stepsContent={renderStepperContent()}
              stepFields={stepFields}
              calculateOffer={calculateOffer}
              isLoading={isCalculationLoading || isOfferCalculating || isProjectCreating}
            />
          </Form>
        )}
      </Formik>
    </Container>
  );
};
