import React, { useState } from 'react';
import { Box, TextField, Typography } from '@mui/material';
import { Button } from '@components/ui-kit/button';
import { Link, useNavigate } from 'react-router-dom';
import { PasswordInput } from '@components/password-input';
import { toast } from 'react-toastify';

import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { useLoginMutation } from '../api/auth/mutations';
import { AxiosError } from 'axios';

export const LogIn = () => {
  const navigate = useNavigate();
  const [login, setLogin] = useState('');
  const [password, setPassword] = useState('');

  const {mutateAsync: signIn} = useLoginMutation();

  const handleLogin = async () => {
    try {
      await signIn({login, password});

      localStorage.setItem('user', JSON.stringify({login}));

      navigate('/');

      toast.success('Logged in successfully!');
    } catch (error: unknown) {
      if (error instanceof AxiosError && error.response?.status === 401) {
        toast.error('Invalid credentials! Please try again.');
      } else {
        toast.error('An unexpected error occurred. Please try again.');
      }
    }
  };

  const handleForgotPassword = () => {
    toast.info('Please contact administrator to reset your password');
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    handleLogin();
  };

  return (
    <Box className="flex h-screen">
      <Box className="w-1/2 relative">
        <img className="w-full h-full" src="./container.png" alt="logo-pic"/>
      </Box>

      <Box className="w-1/2 flex items-center justify-center bg-white">
        <Box className="max-w-md w-full p-8">
          <Box className="mb-10 text-center">
            <Typography variant="h4" component="h1" className="mb-2 font-semibold">
              Welcome back!
            </Typography>
            <Typography variant="body2">
              Please fill in all required fields to successfully log in
            </Typography>
          </Box>
          <form onSubmit={handleSubmit}>
            <Box className="mb-8">
              <TextField
                name="login"
                value={login}
                onChange={(e) => setLogin(e.target.value)}
                label="Login"
                helperText="Please enter the correct email address"
                required
                fullWidth
                error={!login}
              />
            </Box>
            <Box className="mb-8">
              <PasswordInput
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                label="Password"
                error={!password}
              />
            </Box>
            <Box
              className="mb-8 flex items-center justify-between"
              sx={{
                '&:hover': {
                  '& .MuiSvgIcon-root': {
                    color: '#18181B'
                  }
                }
              }}
            >
              <Link onClick={handleForgotPassword} to="#"
                    className="text-secondaryBlack hover:underline underline-offset-4 decoration-2">
                <InfoOutlinedIcon sx={{color: '#71717A'}}/> Forgot password?
              </Link>
            </Box>
            <Button
              disabled={!login || !password}
              variant="contained"
              color="primary"
              fullWidth
              type="submit"
              className="py-2"
            >
              Login
            </Button>
          </form>
        </Box>
      </Box>
    </Box>
  );
};
