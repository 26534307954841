import React from 'react';
import { Checkbox, FormControlLabel, FormGroup } from '@mui/material';

interface CheckboxProps {
  label?: string;
  checked: boolean;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  className?: string;
}

export const FormCheckbox: React.FC<CheckboxProps> = ({
  label,
  checked,
  onChange,
  className,
}) => {
  return (
    <FormGroup>
      <FormControlLabel
        control={
          <Checkbox
            className={className}
            checked={checked}
            onChange={onChange}
            sx={{
              height: "18px",
              color: '#18181B',
              '&.Mui-checked': {
                color: '#18181B',
              },
            }}
          />
        }
        label={label}
        sx={{
          '&.MuiFormControlLabel-labelPlacementEnd': {
            marginRight: 0,
            mr: 0,
          },
          '.MuiFormControlLabel-label': {

            fontWeight: 500,
          },
        }}
      />
    </FormGroup>
  );
};
