import React, { FC } from 'react';
import { InputAdornment, TextField } from '@mui/material';
import Grid from '@mui/material/Grid2';
import EditIcon from '@mui/icons-material/Edit';
import { useFormikContext } from 'formik';
import { CreateProjectForm } from '@pages/create-project/create-project';

interface OfferInputProps {
  name: keyof CreateProjectForm;
  label: string;
  units?: string;
  onChange: (name: string, value: number) => void;
}

export const OfferInput: FC<OfferInputProps> = ({ name, label, units, onChange }) => {
  const { values, setFieldValue } = useFormikContext<CreateProjectForm>();

  const changeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = +event.target.value;
    setFieldValue(name, inputValue);
    onChange(name, inputValue);
  };

  return (
    <Grid container spacing={1} className="flex justify-between items-center pb-1 mt-2 h-[30px]">
      <Grid size={6} className="text-metallic-900 text-sm max-h-full">{label}</Grid>
      <Grid size={4}>
        <TextField
          className="text-metallic-900 text-sm"
          value={values[name] || ''}
          onChange={changeHandler}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <EditIcon fontSize="small" />
              </InputAdornment>
            ),
          }}
          sx={{
            width: '126px',
            height: '24px',
            '.MuiInputBase-root': {
              padding: '3px 10px',
              height: '24px',
            }
          }}
          size="small"
        />
      </Grid>
      <Grid size={2}>
        <span className="text-metallic-900 text-sm ml-1">{units}</span>
      </Grid>
    </Grid>
  );
};
