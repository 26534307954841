import { FC, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Button } from '@mui/material';
import { toast } from 'react-toastify';
import { DialogWrapper } from '@components/ui-kit/dialog';

export const Header: FC = () => {
  const navigate = useNavigate();
  const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false);

  const user = localStorage.getItem('user');
  const username = 'Admin';

  if (!user) return null;

  const handleLogout = () => {
    localStorage.removeItem('user');
    navigate('/login');
    toast.success('Logged out successfully!');
  };

  const openDialog = () => {
    setIsDialogOpen(true);
  };

  return (
    <div className="flex h-[74px] w-full border-b border-b-black-12 justify-center print:hidden">
      <div className="relative w-full max-w-max bg-white flex items-center justify-between px-20 py-5">

        <Link to="/">
          <img src="/logo.svg" alt="Logo" className="h-8"/>
        </Link>

        <div>
          <div className="flex items-center">
            <img src="/avatar.jpg" alt="avatar" className="h-8"/>
            <div className="ml-3 mr-4 pr-4 border-r border-r-black-12">
              <div className="text-black-800 text-sm">{username}</div>
              <div className="text-metallic text-xs">{JSON.parse(user).email}</div>
            </div>
            <div className="">
              <Button
                onClick={openDialog}
                variant="text"
              >
                <img src="/logout.svg" alt="logout" className="mr-2"/>
                Log out
              </Button>
              <DialogWrapper
                isOpen={isDialogOpen}
                setIsOpen={setIsDialogOpen}
                onConfirm={handleLogout}
                title="Log out"
                text="Are you sure you want to log out?"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
