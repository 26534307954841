import { endpoint } from '../api';
import { useQuery } from '@tanstack/react-query';

interface ProjectParams {
  limit: number;
  page: number;
  search?: string;
}

export const fetchProjects = async (params: ProjectParams) => {
  const response = await endpoint.get('project/', {
    params: {
      limit: params.limit,
      page: params.page,
      search: params.search || '',
    },
  });

  return response.data;
};

export const useProjectsQuery = (params: ProjectParams) => {
  return useQuery({
    queryKey: ['projects', params],
    queryFn: () => fetchProjects(params),
  });
};
