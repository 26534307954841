import { FC, ReactNode } from 'react';
import { formatValue } from '@components/create-project/table/helpers';

interface OfferFieldProps {
  name: string;
  value: ReactNode;
  units?: string;
  noBorder?: boolean;
}

export const OfferField: FC<OfferFieldProps> = ({ name, value, units, noBorder }) => {
  return (
    <div className={`flex justify-between items-center pb-1 mt-2 h-[30px] ${noBorder ? '' : 'border-b '}`}>
      <span className="text-metallic-900 text-sm">{name}</span>
      <span className="text-metallic-900 text-sm">{typeof value === 'number' ? formatValue(value) : value} {units}</span>
    </div>
  );
};
