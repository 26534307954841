import React, { FC } from 'react';
import { TableBody } from '@mui/material';
import { ProjectResponse } from '@api/projects/mutations';
import { EmptyRow, RenderRow } from '@components/create-project/table/helpers';
import { useTranslation } from 'react-i18next';

interface OfferEaasTableProps {
  offer: ProjectResponse;
}

export const OfferEaasTable: FC<OfferEaasTableProps> = ({offer}) => {
  const {t} = useTranslation();

  return (
    <TableBody>
      <RenderRow
        offer={offer}
        label={t('project.table.case2.tic')}
        name="TIC"
        caseType="Offer E-a-a-S"
        units="EUR"
      />
      <RenderRow
        offer={offer}
        label={t('project.table.case2.ticSpecific')}
        name="TIC specific"
        caseType="Offer E-a-a-S"
        units="EUR/m2"
        fontStyle="italic"
      />
      <RenderRow
        offer={offer}
        label={t('project.table.case2.ticSpecificOnlyHVAC')}
        name="TIC specific (only HVAC)"
        caseType="Offer E-a-a-S"
        units="EUR/m2"
        fontStyle="italic"
      />
      <RenderRow
        offer={offer}
        label={t('project.table.case2.subsidyPublicGrantAssumed')}
        name="Subsidy/public grant (assumed)"
        caseType="Offer E-a-a-S"
        units="EUR"
      />
      <RenderRow
        offer={offer}
        label={t('project.table.case2.creditLoan')}
        name="Credit/Loan"
        caseType="Offer E-a-a-S"
        units="EUR"
        underline
      />
      <RenderRow
        offer={offer}
        label={t('project.table.case2.ticContributionByCustomerBKZ')}
        name="TIC contribution by customer (BKZ)"
        caseType="Offer E-a-a-S"
        units="EUR"
        fontWeight={700}
      />
      <RenderRow
        offer={offer}
        label={t('project.table.case2.ticContributionBKZPerM2')}
        name="TIC contribution (BKZ) per m2"
        caseType="Offer E-a-a-S"
        units="EUR/m2"
        fontStyle="italic"
      />
      <RenderRow
        offer={offer}
        label={t('project.table.case2.ticContributionBKZPerWE')}
        name="TIC contribution (BKZ) per WE"
        caseType="Offer E-a-a-S"
        units="EUR/WE"
        fontStyle="italic"
      />
      <EmptyRow/>
      <RenderRow
        offer={offer}
        label={t('project.table.case2.averageEnergyPriceTotal')}
        name="Average energy price total (HEAT+WW+TEMP)"
        caseType="Offer E-a-a-S"
        units="EUR/a"
      />
      <RenderRow
        offer={offer}
        label={t('project.table.case2.averageEnergyPriceSpecificPA')}
        name="Average energy price specific p.a. (HEAT+WW+TEMP)"
        caseType="Offer E-a-a-S"
        units="EUR/m2*a"
        fontStyle="italic"
      />
      <RenderRow
        offer={offer}
        label={t('project.table.case2.averageEnergyPriceSpecificPM')}
        name="Average energy price specific p.m. (HEAT+WW+TEMP)"
        caseType="Offer E-a-a-S"
        units="EUR/m2*m"
        fontStyle="italic"
      />
      <EmptyRow/>
      <RenderRow
        offer={offer}
        label={t('project.table.case2.totalCost20yHorizon')}
        name="Total cost on 20y horizon"
        caseType="Offer E-a-a-S"
        units="EUR"
        fontWeight={700}
      />
    </TableBody>
  );
};
