import { useMutation } from '@tanstack/react-query';
import { endpoint } from '../api';

interface LoginCredentials {
  login: string;
  password: string;
}

const login = async (credentials: LoginCredentials): Promise<void> => await endpoint.post('login/', credentials);

export const useLoginMutation = () => {
  return useMutation({
    mutationFn: login,
  });
};
